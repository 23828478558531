import { FC, ReactNode } from 'react';
import { LinkButton } from 'ui/components/button';
import { Card } from 'ui/components/card';
import * as Sentry from '@sentry/react';
export const Page404: FC = () => {
  return (
    <main className="md:p4 box-border h-[500px] w-[500px] p-2 lg:p-8">
      <Card title="Something went wrong" contentClassName="center flex-col p-4 gap-4">
        <h1>Error 404</h1>
        <p>This page doesn&apos;t exist!</p>
        <div className="center gap-4">
          <LinkButton to="/">HOME</LinkButton>
          <LinkButton to="/trade">TRADE</LinkButton>
        </div>
      </Card>
    </main>
  );
};

export const ErrorBoundary: FC<{
  children: ReactNode;
  message?: string;
  errorChildren?: ReactNode;
}> = ({ children, message, errorChildren }) => {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => (
        <div className="center h-full w-full flex-col gap-6 text-white">
          {errorChildren ?? message ?? (
            <>
              <p>This page encountered an error:</p>
              <pre className="rounded-lg bg-white/10 p-2 font-mono text-xs">
                {typeof error === 'string' ? error : (error?.toString?.() ?? null)}
              </pre>
            </>
          )}
          <div className="center gap-4">
            <LinkButton reloadDocument to={{ pathname: '/', search: `?d=${Date.now()}` }}>
              HOME
            </LinkButton>
            <LinkButton reloadDocument to={{ search: `?d=${Date.now()}` }}>
              RELOAD
            </LinkButton>
          </div>
        </div>
      )}>
      {children}
    </Sentry.ErrorBoundary>
  );
};
