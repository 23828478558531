export const NATIVE_TOKEN_DECIMALS = 6;
export const LP_TOKEN_DECIMALS = 6;
export const COMMISSION_RATE = 0.02; // 2%

export const IS_DEV =
  window.location.hostname.includes('development') || window.location.hostname.includes('localhost');
export const IS_STAGING = window.location.hostname.includes('staging');
export const IS_PROD = window.location.hostname.includes('terraport.finance');
export const IS_LOCAL = window.location.hostname.includes('localhost');

export const classicNetwork = {
  name: 'classic',
  chainID: 'columbus-5',
  rpc: 'https://terra-classic-rpc.publicnode.com:443',
  lcd: 'https://terra-classic-fcd.publicnode.com',
  fcd: 'https://terra-classic-fcd.publicnode.com',
  explorer: 'https://finder.terraport.finance/mainnet/',
  id: 'columbus-5',
  gasPrices: { uusd: 0.15 },
  fee: { gasPrice: 0.15, amount: 100000 }, // 0.1 USTC
  v3FactoryContract: 'terra1m8zz7q49x8phrfwc0rxep77l2u6hf7tm2arv2rmzk5c9lg7p6ncqu3y4zg',
  factoryContract: 'terra1n75fgfc8clsssrm2k0fswgtzsvstdaah7la6sfu96szdu22xta0q57rqqr',
  routerContract: 'terra1a08zwcau6kns52tunfhg46ytywxxjk9dng4ewdk6ujqtwtq0wmqs89v6ga',
  vestingContract: 'terra19v3vkpxsxeach4tpdklxaxc9wuwx65jqfs6jzm5cu5yz457hhgmsp4a48n',
  tokenContract: 'terra1ex0hjv3wurhj4wgup4jzlzaqj4av6xqd8le4etml7rg9rs207y4s8cdvrp',
  stakingContract: 'terra134ummlrj2rnv8h8rjhs6a54fng0xlg8wk7a2gwu6vj42pznkf6xs95966d',

  liquidstakinghubContract: 'terra16eh2j2cy8q42xcgytyu9sjmsyeeyyc3w6y4ra7u2fddsvvtjnaxq5a0fjx',
  liquidstakingrewardContract: 'terra13yyj7pzlw6kkdt9ehy5u0l3k677n6mwrr4437902mh94srup5glqqmtw5s',
  liquidstakingFarm: 'terra1pwj2mz4r37ye2r7xk7vzjepy79htdpcp69dm92fwz2jdvqcuchfs9n9kej',
  liquidstakingLPToken: 'terra18zalhyvsw72cx2gwz7fsc4wlqecjavxnhcn8lsa63smc09rw9zwqe5jgl0',
  liquidstakingBondedLunaContract: 'terra1y4plh8lxjy8ywf28wwuxjac0qmk20fuvg98kev2ey78gg82xafuq0nuqyy',
  liquidStakingPair: 'terra16p0fhpm7a09t3t5xnj7ukfeats77tc25032rl6l00n4w2f7wz9xsyve64x',

  vestingContractInvestors: 'terra1y9nyupwjcmjw3t9lr8yvqkzt0gzt4hr2m0nk29ap2amy3qyz5njsgqqjw5',
  governanceContract: 'terra1m67k3ak5d05k7tzukstkujxptw0tnwt8cmf5kcxm6vwd403l4teshm07ls',
  burnContract: 'terra1rd8f2mhdhgtsfk5afcpfj78wzkljc4lns6t2rhwftvtwzdz95cuqmt7rmm',
  allowedPairContracts: [],
};

export const terraportNetworks = {
  classic: classicNetwork,
};
