import { FC, ReactNode } from 'react';
import { Footer, MobileNavMenu } from './footer';
import { Header } from './header';
import { ParallaxBackground } from 'ui/components/planet';
import Swapper, { QuickSwapButton } from './swapper';
import { infoModals, InfoModals } from './info-modals';
import { useLocation } from 'react-router';
import clsx from 'clsx';
import { walletState } from 'state/wallet-state';
import { includes } from 'lodash';

export const MainLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const location = useLocation();
  return (
    <div
      id="layout"
      className="flex h-dvh flex-col items-center justify-start overflow-y-auto overflow-x-hidden bg-background4 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-white/20">
      {!location.pathname.startsWith('/launchpad') && <ParallaxBackground />}
      <Header />
      <div className="sticky top-[98px] z-[3] flex w-full flex-col md:top-[102px]">
        <TOUHeader />
      </div>
      {children}
      <Footer />
      <MobileNavMenu />
      <Swapper />
      <QuickSwapButton />
      <InfoModals />
    </div>
  );
};

export const TOUHeader = () => {
  const location = useLocation();
  return includes(['US', 'GB'], walletState.use.region()) && location.pathname === '/' ? (
    <div
      className={clsx(
        'w-full items-center justify-center gap-2 text-wrap bg-background2 px-4 text-center md:py-2',
      )}>
      <span className="text-xs text-white md:text-sm">
        Don’t invest unless you’re prepared to lose all the money you invest. This is a high-risk investment
        and you should not expect to be protected if something goes wrong.{' '}
      </span>
      <span
        className="md cursor-pointer text-xs text-accent-btn underline md:text-sm"
        onClick={() => infoModals.set.termsOfUse(true)}>
        Take 2 mins to learn more.
      </span>
    </div>
  ) : null;
};
