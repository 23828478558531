import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import axios from 'axios';
import { closeWalletConnectModal, disconnect, walletState } from '../../state/wallet-state';
import { toast } from 'react-toastify';

export const baseUrl = 'https://service.terraport.finance'; // temporarily forcing Prod BE in dev

export const useGetHealthQuery = () => {
  return useSuspenseQuery({
    queryKey: ['health'],
    refetchInterval: 30000,
    queryFn: async () => {
      const res = await axios.get(`${baseUrl}/health`);
      return res.data;
    },
  });
};

export const useGetMeQuery = () => {
  const jwt = walletState.use.jwt();
  return useQuery({
    queryKey: ['me', jwt],
    enabled: !!jwt,
    retry: false,
    refetchInterval: 30000,
    queryFn: async () => {
      const res = await axios
        .get(`${baseUrl}/me`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        })
        .catch(({ response }) => {
          if (response.status === 401) {
            setTimeout(() => {
              closeWalletConnectModal();
              toast.error('Unexpected server error');
              disconnect();
            }, 10);
          }
        });
      return res.data;
    },
  });
};
