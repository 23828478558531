import { useVestingPermission } from 'api/terraport/vesting';
import { SwapIcon, ShipIcon, TradeIcon, GovernanceIcon, MoreListIcon } from 'ui/icons/NavbarIcons';
import { compact } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { NavItem, MultiNavItem } from 'types/routes';
import { infoModals } from 'ui/layout/info-modals';

export const useAppRoutes = () => {
  const { t } = useTranslation();
  const vesting = useVestingPermission();
  const routes = useMemo<Array<NavItem | MultiNavItem>>(
    () => [
      {
        title: t('common.trade'),
        pathname: '/trade',
        icon: SwapIcon,
      },
      {
        multipath: true,
        title: t('common.earn'),
        icon: ShipIcon,
        paths: compact([
          {
            title: t('common.staking'),
            multipath: false,
            pathname: '/staking',
            img: '/assets/img/logos/Diamond01_1024.webp',
          },
          {
            title: t('common.farming'),
            multipath: false,
            pathname: '/farming',
            img: '/assets/img/logos/FarmPlant01_1024.webp',
          },
          {
            title: 'Liquid Staking',
            pathname: '/liquidstaking',
            img: '/assets/img/liquid-staking/mint-logo.webp',
          },
        ]),
      },
      {
        title: t('common.other'),
        icon: MoreListIcon,
        multipath: true,
        paths: compact([
          {
            title: t('common.development'),
            mobileTitle: 'Dev',
            icon: TradeIcon,
            multipath: false,
            pathname: '/development',
            img: '/assets/img/logos/AddLiquidity_1024.webp',
          },
          {
            title: t('common.governance'),
            mobileTitle: 'Gov',
            icon: GovernanceIcon,
            multipath: false,
            pathname: '/governance',
            img: '/assets/img/logos/Proposal01_1024.webp',
          },
          {
            title: t('common.history'),
            multipath: false,
            pathname: '/history-launchpad',
            img: '/assets/img/launchpad/ship.webp',
          },
          {
            title: t('routes.docs'),
            pathname: 'https://terraport.gitbook.io/terraport-docs/',
            openOtherPage: true,
            img: '/assets/img/logos/Cube01_Blue_RadialShadow_1024.webp',
          },
          {
            title: t('routes.risk_advice'),
            pathname: '#',
            onClick: () => {
              infoModals.set.termsOfUse(true);
            },
            img: '/assets/img/logos/Cube01_Magenta_RadialShadow_1024.webp',
          },
          {
            hidden: () => !vesting.found,
            title: t('common.vesting'),
            pathname: '/vesting',
            img: '/assets/img/logos/Terracoin_RadialShadow_1024.webp',
          },
        ]),
      },
    ],
    [t, vesting],
  );
  const { pathname } = useLocation();
  const currentIndex = useMemo(
    () =>
      routes.findIndex((r) =>
        r.multipath
          ? r.paths.find((sr) => pathname.startsWith(sr.pathname))
          : pathname.startsWith(r.pathname),
      ),
    [pathname, routes],
  );
  return useMemo(
    () => ({
      routes,
      currentIndex,
    }),
    [routes, currentIndex],
  );
};
