import { convertDenomToMicroDenom } from '../../helper/utils';
import { classicNetwork } from '../../consts/networks';
import { getAddress, walletState } from '../../state/wallet-state';
import { MsgExecuteContract } from 'cosmes/client';
import { Asset } from 'types/pairs';

export const createPairMsg = ({ firstToken, secondToken }: { firstToken: Asset; secondToken: Asset }) => {
  const address = walletState.get.address()!;
  const asset1 = firstToken?.contract_addr?.startsWith('terra')
    ? {
        token: {
          contract_addr: firstToken.contract_addr,
        },
      }
    : {
        native_token: {
          denom: firstToken.contract_addr,
        },
      };
  const asset2 = secondToken?.contract_addr?.startsWith('terra')
    ? {
        token: {
          contract_addr: secondToken.contract_addr,
        },
      }
    : {
        native_token: {
          denom: secondToken.contract_addr,
        },
      };

  return new MsgExecuteContract({
    sender: address,
    contract: classicNetwork.factoryContract,
    msg: {
      create_pair: {
        asset_infos: [asset1, asset2],
      },
    },
    funds: [],
  });
};

export const swapMsg = async ({
  fromAmount,
  fromAsset,
  swap_operations,
}: {
  fromAmount: string;
  fromAsset: Asset;
  swap_operations: unknown;
}) => {
  const address = getAddress();
  const fromValue = convertDenomToMicroDenom(fromAmount, fromAsset.decimals);
  const funds = [];
  if (!fromAsset?.contract_addr?.startsWith('terra')) {
    funds.push({
      denom: fromAsset.contract_addr,
      amount: fromValue,
    });
  }

  const msg = {
    execute_swap_operations: swap_operations,
  };
  if (fromAsset?.contract_addr?.startsWith('terra')) {
    return new MsgExecuteContract({
      msg: {
        send: {
          contract: classicNetwork.routerContract,
          amount: fromValue,
          msg: btoa(JSON.stringify(msg)),
        },
      },
      sender: address,
      contract: fromAsset.contract_addr,
      funds,
    });
  }
  return new MsgExecuteContract({
    sender: address,
    contract: classicNetwork.routerContract,
    funds,
    msg,
  });
};

export const swapTreasuryMsg = ({ treasuryContract }: { treasuryContract: string }) => {
  const address = walletState.get.address()!;
  return new MsgExecuteContract({
    sender: address,
    contract: treasuryContract,
    msg: {
      swap_all: {},
    },
    funds: [],
  });
};

export const distributeTreasuryMsg = ({ treasuryContract }: { treasuryContract: string }) => {
  const address = walletState.get.address()!;
  return new MsgExecuteContract({
    sender: address,
    contract: treasuryContract,
    msg: {
      distribute: {},
    },
    funds: [],
  });
};
